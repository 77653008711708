<template></template>
<script>
export default {
	name: 'AutoLogout',
	data() {
		return {
			timeout: null,
			timer: 2000 * 60, // 2 minutes popup then logout
			title: document.title,
		}
	},
	watch: {
		$route(to, from) {
			if (this.myUser) {
				this.countDown()
			}
		},
	},
	async created() {},
	mounted() {
		if (this.myUser) {
			localStorage.setItem('lastActiveTime', Date.now())
			this.countDown()
		}
	},
	methods: {
		countDown() {
			if (this.myUser) {
				clearTimeout(this.timeout)
				let timerInterval
				this.timeout = setTimeout(() => {
					var original = document.title
					var interval = setInterval(this.flashTitle, 1000, original)
					var swal = this.$swal
						.fire({
							title: 'Automatisk log ud',
							html: "På grund af inaktivitet vil du snart blive logget ud om <strong></strong> minutter. <br /><br/> Tryk på ''forlæng'' for at forblive logget ind.",
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							cancelButtonText: 'Log ud',
							confirmButtonText: 'Forlæng',
							timer: this.timer,
							timerProgressBar: true,
							allowOutsideClick: false,
							allowOutsideEscapeKey: false,
							didOpen: () => {
								const content = this.$swal.getHtmlContainer()
								const $ = content.querySelector.bind(content)

								timerInterval = setInterval(() => {
									this.$swal.getHtmlContainer().querySelector('strong').textContent = this.millisToMinutesAndSeconds(this.$swal.getTimerLeft())
								}, 100)
							},
						})
						.then(async (result) => {
							clearInterval(interval)
							this.setTitle(original)
							if (result.isConfirmed) {
								localStorage.setItem('lastActiveTime', Date.now())
								this.countDown()
							} else if (result.isDismissed) {
								this.logOut()
							}
						})
					this.signOut() // Time logout
				}, 5400000) // 1.5 hour
			}
		},
		async signOut() {
			this.timeout = setTimeout(() => {
				this.$store.dispatch('auth/signOutAction')
				this.$router.push({
					name: 'Login',
				})
			}, this.timer)
		},
		async logOut() {
			clearTimeout(this.timeout)
			this.$store.dispatch('auth/signOutAction')
			this.$router.push({
				name: 'Login',
			})
		},
		flashTitle(original) {
			if (document.title == original) {
				document.title = '\u200E'
			} else {
				document.title = original
			}
		},
		setTitle(original) {
			document.title = original
		},
		millisToMinutesAndSeconds(millis) {
			var minutes = Math.floor(millis / 60000)
			var seconds = ((millis % 60000) / 1000).toFixed(0)
			return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
		},
	},
	computed: {
		myUser() {
			return this.$store.getters['auth/user']
		},
	},
}
</script>

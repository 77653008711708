<template>
	<b-navbar toggleable="lg" navbar-dark class="navigation">
		<b-navbar-brand v-if="$can('read', 'Companies')">
			<router-link to="/company/list">
				<img :src="require(`@/assets/images/logos/${config.customization.logo}`)" class="d-inline-block align-top" />
			</router-link>
		</b-navbar-brand>
		<b-navbar-brand v-else>
			<img :src="require(`@/assets/images/logos/${config.customization.logo}`)" class="d-inline-block align-top" />
		</b-navbar-brand>
		<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
		<b-collapse id="nav-collapse" is-nav>
			<b-navbar-nav class="d-flex">
				<!-- <b-nav-item class="mx-auto" to="/home" v-if="$can('read', 'Home')">
          {{ $t("navigation.dashboard") }}
        </b-nav-item> -->
				<b-nav-item
					v-for="policy in policies"
					:key="policy.policyId"
					class="mx-auto"
					:to="{
						name: 'CompanyEmployeeInfo',
						params: { companyId: policy.companyId, employeeId: employeeId, policyId: policy.policyId },
					}"
					v-if="$can('read', 'EmployeePage')"
					>Min side</b-nav-item
				>
				<b-nav-item class="mx-auto" to="/company/list" v-if="$can('read', 'Companies')">{{ $t('navigation.company') }}</b-nav-item>
				<b-nav-item class="mx-auto" to="/supplier/list" v-if="$can('read', 'Suppliers')">{{ $t('navigation.supplier') }}</b-nav-item>
				<b-nav-item class="mx-auto" to="/user/list" v-if="$can('read', 'Users')">{{ $t('navigation.user') }}</b-nav-item>
				<b-nav-item class="mx-auto" to="/resignedCompany" v-if="$can('create', 'User') && isAdmin">Fratrådte</b-nav-item>
				<b-nav-item class="mx-auto" to="/signup" v-if="$can('create', 'User') && isAdmin">{{ $t('navigation.create') }}</b-nav-item>

				<b-nav-item class="mx-auto" to="/rapport" v-if="isAdmin">Rapport</b-nav-item>
			</b-navbar-nav>
			<b-navbar-nav class="ml-auto d-flex">
				<b-nav-item right href="/" class="mx-auto" v-if="!myUser">
					{{ $t('navigation.login') }}
				</b-nav-item>
				<b-nav-item :to="'/profile/' + uid + '/info'" right class="mx-auto" v-if="myUser">
					{{ myUser.email }}
				</b-nav-item>
				<b-nav-item right class="mx-auto" v-if="myUser" @click="signOut">
					{{ $t('navigation.Logout') }}
				</b-nav-item>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>
<script>
import config from '../../../../conf'
export default {
	name: 'NavigationBar',
	mixins: [],
	data() {
		return {
			config: config,
		}
	},
	watch: {},
	methods: {
		async signOut() {
			await this.$store.dispatch('auth/signOutAction')
			console.log('Sign out')
			this.$router.push({
				name: 'Login',
			})
		},
		
	},
	computed: {
		employeeId() {
			return this.$store.getters['auth/uid']
		},
		isAdmin() {
			return this.$store.getters['auth/isAdmin']
		},
		isAdvisor() {
			return this.$store.getters['auth/isAdvisor']
		},
		myUser() {
			return this.$store.getters['auth/user']
		},
		uid() {
			return this.$store.getters['auth/uid']
		},
		
		policies() {
			console.log(this.$store.getters['auth/userData'])
			return this.$store.getters['auth/userData']?.policies ?? []
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/components/navigation.scss';
</style>

<template>
	<b-modal id="pop" size="lg" scrollable hide-footer :title="$t('changelog.changelog')">
		<template v-for="(item, index) in $t('changelog.updates')">
			<div class="row" :key="index">
				<div class="col-12">
					<b>{{ item.version }} </b> - <i>{{ item.date }}</i>
				</div>
				<div class="col-12">
					<ul>
						<template v-for="(item, index) in item.list">
							<li :key="index">{{ item }}</li>
						</template>
					</ul>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'Pop',

	mounted() {
		// this.$bvModal.show("pop");
	},
	methods: {
		show() {
			this.$bvModal.show('pop')
		},
	},
	computed: {},
}
</script>

<style lang="scss" scoped></style>

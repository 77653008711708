<template>
	<section>
		<NavigationBar />
		<div class="container-fluid" v-if="!this.loaded">
			<div class="loader"></div>
		</div>
		<div class="container-fluid" v-show="this.loaded">
			<router-view v-on:updateLoader="loader"></router-view>
		</div>
		<Footer />
		<AutoLogout />
	</section>
</template>
<script>
import NavigationBar from '../components/NavigationBar'
import Footer from '../components/Footer'
import AutoLogout from '../components/AutoLogout.vue'
import config from '../../../../conf'
export default {
	name: 'Home',
	components: {
		NavigationBar,
		Footer,
		AutoLogout
	},
	data() {
		return {
			loaded: false,
			config: config,
		}
	},
	head: {
		link: [
			{
				rel: 'icon',
				href: require(`@/assets/images/logos/` + config.customization.favicon),
				sizes: '16x16',
				type: 'image/png',
			},
		],
	},
	created() {},
	watch: {
		$route(to, from) {
			this.loaded = false
		},
	},
	methods: {
		loader() {
			this.loaded = true
		},
	},
	computed: {},
}
</script>
<style lang="scss"></style>

<template>
	<footer>
		<div class="row justify-content-center m-0">
			<div class="col-auto">
				<b>{{ config.customization.name }}</b>
			</div>
		</div>
		<div class="row justify-content-center m-0">
			<div class="col-auto">
				<span class="pr-2">
					<b class="d-inline">Til:&nbsp;</b>
					<a :href="'tel:' + config.customization.phone">{{ config.customization.phone }}</a>
				</span>
				<span class="pl-2">
					<b class="d-inline">Email:&nbsp;</b>
					<a :href="'mailto:' + config.customization.email">{{ config.customization.email }}</a>
				</span>
			</div>
		</div>
		<div align="right" class="pr-2" style="margin-top: -1.7em">
			<button class="btn-b-gone" @click="toggleChangelog" :disabled="!canShowVersion"><b>Version:</b> {{ appVersion }}</button>
		</div>
		<Changelog ref="changelog" />
	</footer>
</template>

<script>
import Changelog from '@/modules/global/components/Changelog.vue'
import config from '../../../../conf'

export default {
	name: 'Footer',
	data() {
		return {
			config: config,
		}
	},
	components: {
		Changelog,
	},
	methods: {
		toggleChangelog() {
			this.$refs.changelog.show()
		},
	},
	computed: {
		appVersion() {
			return this.$store.getters['app/appVersion']
		},
		canShowVersion() {
			var canShowVersion = this.$store.getters['auth/isAdvisor'] || this.$store.getters['auth/isAdmin']
			return canShowVersion
		},
	},
}
</script>

<style lang="scss">
@import '@/styles/components/footer.scss';
</style>
